var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-7 py-4",staticStyle:{"background":"#f7f7f7","height":"auto"}},[(_vm.$vuetify.breakpoint.smAndUp)?_c('v-sheet',{staticClass:"col-md-12 col-lg-12 col-sm-12 col-xs-12",attrs:{"tile":""}},[_c('v-row',[_c('v-btn',{staticClass:"text-capitalize px-7 mr-5",staticStyle:{"border-radius":"10px","margin":"20px 5px"},attrs:{"color":"#38227A","dark":"","height":"45px"},on:{"click":_vm.newMonthCalender}},[_c('span',[_vm._v("Month")])]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize px-7 mr-5",staticStyle:{"border-radius":"10px","margin-top":"20px","margin-left":"21px"},attrs:{"label":"Go To Date","color":"#38227A","dark":"","height":"45px"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Go To Date")])])]}}],null,false,4271997486),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false},"change":function($event){return _vm.getTodayCalendarData()}},model:{value:(_vm.list_date),callback:function ($$v) {_vm.list_date=$$v},expression:"list_date"}})],1),_c('v-btn',{staticClass:"px-2",staticStyle:{"border-radius":"10px","margin":"20px 5px","margin-left":"13px","height":"44px","min-width":"90px"},attrs:{"color":"#38227A","dark":""},on:{"click":function($event){return _vm.toggleAddFilterModal({ show: true, type: 'add' })}}},[_c('span',{staticStyle:{"margin-left":"5px","text-transform":"capitalize"}},[_vm._v("Filter")])])],1)],1):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('v-sheet',{staticClass:"col-md-4 col-lg-4 col-sm-12 col-xs-12",attrs:{"tile":""}},[_c('v-row',[_c('v-btn',{staticClass:"text-capitalize px-7 mr-5",staticStyle:{"border-radius":"10px","margin":"0px 0px","width":"10px","font-size":"11px"},attrs:{"color":"#38227A","dark":"","height":"22px"},on:{"click":_vm.newMonthCalender}},[_c('span',[_vm._v("Month")])]),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-capitalize px-7 mr-5",staticStyle:{"border-radius":"10px","width":"90px","font-size":"11px"},attrs:{"label":"Go To Date","color":"#38227A","dark":"","height":"22px"}},'v-btn',attrs,false),on),[_c('span',[_vm._v("Go To Date")])])]}}],null,false,2189625706),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":""},on:{"input":function($event){_vm.menu = false},"change":function($event){return _vm.getTodayCalendarData()}},model:{value:(_vm.list_date),callback:function ($$v) {_vm.list_date=$$v},expression:"list_date"}})],1),_c('v-btn',{staticClass:"px-2",staticStyle:{"border-radius":"10px","height":"22px","min-width":"55px","font-size":"11px"},attrs:{"color":"#38227A","dark":""},on:{"click":function($event){return _vm.toggleAddFilterModal({ show: true, type: 'add' })}}},[_c('span',{staticStyle:{"text-transform":"capitalize"}},[_vm._v("Filter")])])],1)],1):_vm._e(),_c('br'),(_vm.loading)?_c('img',{staticStyle:{"margin-left":"42%"},attrs:{"src":require("@/assets/thingsToDo/loader5.gif"),"width":"100","height":"100"}}):_vm._e(),_c('div',[(this.list_date)?_c('h3',[_c('span',{staticStyle:{"margin-left":"25px"}},[_vm._v(_vm._s(_vm.dayOfWeek))]),_c('br'),_c('v-icon',{on:{"click":function($event){_vm.goToBackDateList();
          _vm.getTodayCalendarData();}}},[_vm._v("mdi-arrow-left")]),_vm._v(_vm._s(_vm.formattedDate)+" "),_c('v-icon',{on:{"click":function($event){_vm.goToNextDateList();
          _vm.getTodayCalendarData();}}},[_vm._v("mdi-arrow-right")])],1):_c('h3',[(!this.nextFlag)?_c('span',{staticStyle:{"margin-left":"25px"}},[_vm._v(_vm._s(_vm.today))]):_c('span',{staticStyle:{"margin-left":"25px"}},[_vm._v(_vm._s(_vm.dayOfWeek1))]),_c('br'),_c('v-icon',{on:{"click":function($event){_vm.goToBackDate();
          _vm.getWeekDayCalendarData();}}},[_vm._v("mdi-arrow-left")]),_vm._v(_vm._s(this.currentDateDisplay1)),_c('v-icon',{on:{"click":function($event){_vm.goToNextDate();
          _vm.getWeekDayCalendarData();}}},[_vm._v("mdi-arrow-right")])],1),_vm._l((_vm.listcalendar.items),function(item){return _c('div',{key:item.id},[(_vm.$vuetify.breakpoint.smAndUp)?_c('div',{staticStyle:{"font-size":"14px"}},[(item.event_type == 'Pto')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("PTO")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"145px"}},[_vm._v(_vm._s(item.start_time_str))]),_vm._v("                "),_c('strong',{staticStyle:{"color":"#a3c585","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListPtoInfoModal({
                    show: true,
                    id: item.id,
                  })}}},[_vm._v("PTO("+_vm._s(item.player_name)+")")])]),_c('br')]):_vm._e(),(item.event_type == 'Task')?_c('div',{staticClass:"text-left row-item"},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Task")]),_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","color":"#f9b4f6","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListTaskInfoModal({
                    show: true,
                    id: item.id,
                  })}}},[_vm._v(_vm._s(item.task_title))])]),_c('br')]):_vm._e(),(item.event_type == 'assembly')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Free Assembly")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"75px"}},[_vm._v(_vm._s(item.assemblies[0].start_time_str))]),_vm._v("                "),(item.assemblies[0].team_name)?_c('strong',{staticStyle:{"color":"#41a7f5","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListAssemblyInfoModal({
                    show: true,
                    id: item.assemble_id,
                  })}}},[_vm._v(" "+_vm._s(item.assemblies[0].assemble_city)+","+_vm._s(item.assemblies[0].assemble_state)+"-FA("+_vm._s(item.assemblies[0].sequence)+")("+_vm._s(item.assemblies[0].team_name)+") ")]):(item.assemblies[0].player_list != null)?_c('strong',{staticStyle:{"color":"#41a7f5","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListAssemblyInfoModal({
                    show: true,
                    id: item.assemble_id,
                  })}}},[_vm._v(" "+_vm._s(item.assemblies[0].assemble_city)+","+_vm._s(item.assemblies[0].assemble_state)+"-FA("+_vm._s(item.assemblies[0].sequence)+")("+_vm._s(item.assemblies[0].player_list .map(function (player) { return player.name; }) .join(","))+") ")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListAssemblyInfoModal({
                    show: true,
                    id: item.assemble_id,
                  })}}},[_vm._v(" "+_vm._s(item.assemblies[0].assemble_city)+","+_vm._s(item.assemblies[0].assemble_state)+"-FA("+_vm._s(item.assemblies[0].sequence)+")(Unassigned) ")])]),_c('br')]):_vm._e(),(item.event_type == 'game')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Game")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"137px"}},[_vm._v(_vm._s(item.games[0].start_time_str))]),_vm._v("                "),(item.games[0].team_name == 'KING TEAM')?_c('strong',{staticStyle:{"color":"#27e600","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")]):(item.games[0].team_name == 'BWY TEAM')?_c('strong',{staticStyle:{"color":"#b96a59","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")]):(item.games[0].team_name == 'RR TEAM')?_c('strong',{staticStyle:{"color":"#ca5cdd","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")]):(item.games[0].team_name == null)?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"(Unassigned) ")]):_c('strong',{staticStyle:{"color":"#ffcccb","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")])]),_c('br')]):_vm._e(),(item.event_type == 'hotel')?_c('div',{staticClass:"text-left row-item"},[(item.hotel_detail[0].game != null)?_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Hotel")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"145px"}}),_vm._v("                                 "),(item.hotel_detail[0].team_name != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].hotel_city)+","+_vm._s(item.hotel_detail[0].hotel_state)+"-Hotel("+_vm._s(item.hotel_detail[0].team_name)+") ")]):(item.hotel_detail[0].player_list != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].hotel_city)+","+_vm._s(item.hotel_detail[0].hotel_state)+"-Hotel("+_vm._s(item.hotel_detail[0].player_list .map(function (player) { return player.name; }) .join(","))+") ")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].hotel_city)+","+_vm._s(item.hotel_detail[0].hotel_state)+"-Hotel(Unassigned) ")])]):(item.hotel_detail[0].game == null)?_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Hotel")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"145px"}}),_vm._v("                                 "),(item.hotel_detail[0].team_name != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].city)+","+_vm._s(item.hotel_detail[0].hotel_state_paid)+"-Hotel("+_vm._s(item.hotel_detail[0].team_name)+") ")]):(item.hotel_detail[0].player_list != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].city)+","+_vm._s(item.hotel_detail[0].hotel_state_paid)+"-Hotel("+_vm._s(item.hotel_detail[0].player_list .map(function (player) { return player.name; }) .join(","))+") ")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].city)+","+_vm._s(item.hotel_detail[0].hotel_state_paid)+"-Hotel(Unassigned) ")])]):_vm._e(),_c('br')]):_vm._e(),(item.event_type == 'kick_off_date')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Wizfit")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"137px"}},[_vm._v(_vm._s(item.campaigns[0].kick_off_start_time_str))]),_vm._v("                "),(
                  item.school_name != null &&
                  item.campaigns[0].kick_off_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-KO("+_vm._s(item.campaigns[0].kick_off_team_name)+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].kick_off_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-KO("+_vm._s(item.campaigns[0].kick_off_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].kick_off_player_list == null &&
                  item.campaigns[0].kick_off_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-KO(Unassigned)")]):_vm._e(),(
                  item.district_name != null &&
                  item.campaigns[0].kick_off_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-KO("+_vm._s(item.campaigns[0].kick_off_team_name)+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].kick_off_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-KO("+_vm._s(item.campaigns[0].kick_off_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].kick_off_player_list == null &&
                  item.campaigns[0].kick_off_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-KO(Unassigned)")]):_vm._e()]),_c('br')]):_vm._e(),(item.event_type == 'gold_ball_giveaway_date')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Wizfit")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"137px"}},[_vm._v(_vm._s(item.campaigns[0].gold_ball_start_time_str))]),_vm._v("                "),(
                  item.school_name != null &&
                  item.campaigns[0].gold_ball_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GB("+_vm._s(item.campaigns[0].gold_ball_team_name)+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].gold_ball_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GB("+_vm._s(item.campaigns[0].gold_ball_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].gold_ball_player_list == null &&
                  item.campaigns[0].gold_ball_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GB(Unassigned)")]):_vm._e(),(
                  item.district_name != null &&
                  item.campaigns[0].gold_ball_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GB("+_vm._s(item.campaigns[0].gold_ball_team_name)+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].gold_ball_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GB("+_vm._s(item.campaigns[0].gold_ball_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].gold_ball_player_list == null &&
                  item.campaigns[0].gold_ball_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GB(Unassigned)")]):_vm._e()]),_c('br')]):_vm._e(),(item.event_type == 'graduation_celebration_date')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Wizfit")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"137px"}},[_vm._v(_vm._s(item.campaigns[0].graduation_start_time_str))]),_vm._v("                "),(
                  item.school_name != null &&
                  item.campaigns[0].graduation_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GR("+_vm._s(item.campaigns[0].graduation_team_name)+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].graduation_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GR("+_vm._s(item.campaigns[0].graduation_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].graduation_player_list == null &&
                  item.campaigns[0].graduation_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GR(Unassigned)")]):_vm._e(),(
                  item.district_name != null &&
                  item.campaigns[0].graduation_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GR("+_vm._s(item.campaigns[0].graduation_team_name)+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].graduation_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GR("+_vm._s(item.campaigns[0].graduation_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].graduation_player_list == null &&
                  item.campaigns[0].graduation_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GR(Unassigned)")]):_vm._e()]),_c('br')]):_vm._e(),(item.event_type == 'custom')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Custom Event")]),_vm._v("  "),_c('span',{staticStyle:{"margin-left":"75px"}},[_vm._v(_vm._s(item.paid_events[0].start_time_str))]),_vm._v("                "),(item.paid_events[0].team_name != null)?_c('strong',{staticStyle:{"color":"#fbc02d","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListCustomInfoModal({
                    show: true,
                    id: item.paid_event_id,
                  })}}},[_vm._v(_vm._s(item.paid_events[0].event_name)+"-"+_vm._s(item.paid_events[0].city)+","+_vm._s(item.paid_events[0].state_name)+"-("+_vm._s(item.paid_events[0].team_name)+")")]):(item.paid_events[0].player_list != null)?_c('strong',{staticStyle:{"color":"#fbc02d","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListCustomInfoModal({
                    show: true,
                    id: item.paid_event_id,
                  })}}},[_vm._v(_vm._s(item.paid_events[0].event_name)+"-"+_vm._s(item.paid_events[0].city)+","+_vm._s(item.paid_events[0].state_name)+"-("+_vm._s(item.paid_events[0].player_list .map(function (player) { return player.name; }) .join(","))+")")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListCustomInfoModal({
                    show: true,
                    id: item.paid_event_id,
                  })}}},[_vm._v(_vm._s(item.paid_events[0].event_name)+"-"+_vm._s(item.paid_events[0].city)+","+_vm._s(item.paid_events[0].state_name)+"-(Unassigned)")])]),_c('br')]):_vm._e()]):_vm._e(),(_vm.$vuetify.breakpoint.xsOnly)?_c('div',{staticStyle:{"font-size":"14px"}},[(item.event_type == 'Pto')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"215px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("PTO")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"100px"}},[_vm._v(_vm._s(item.start_time_str))]),_vm._v("                "),_c('strong',{staticStyle:{"color":"#a3c585","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListPtoInfoModal({
                    show: true,
                    id: item.id,
                  })}}},[_vm._v("PTO("+_vm._s(item.player_name)+")")])]),_c('br')]):_vm._e(),(item.event_type == 'Task')?_c('div',{staticClass:"text-left row-item"},[_c('span',{staticStyle:{"font-size":"16px","margin-left":"-200px"}},[_vm._v("Task")]),_c('div',{staticStyle:{"text-align":"justify","font-size":"16px","margin-left":"245px"}},[_c('span',{staticStyle:{"font-size":"16px","color":"#f9b4f6","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListTaskInfoModal({
                    show: true,
                    id: item.id,
                  })}}},[_vm._v(_vm._s(item.task_title))])]),_c('br')]):_vm._e(),(item.event_type == 'assembly')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("Free Assembly")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"40px"}},[_vm._v(_vm._s(item.assemblies[0].start_time_str))]),_vm._v("                "),(item.assemblies[0].team_name)?_c('strong',{staticStyle:{"color":"#41a7f5","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListAssemblyInfoModal({
                    show: true,
                    id: item.assemble_id,
                  })}}},[_vm._v(" "+_vm._s(item.assemblies[0].assemble_city)+","+_vm._s(item.assemblies[0].assemble_state)+"-FA("+_vm._s(item.assemblies[0].sequence)+")("+_vm._s(item.assemblies[0].team_name)+") ")]):(item.assemblies[0].player_list != null)?_c('strong',{staticStyle:{"color":"#41a7f5","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListAssemblyInfoModal({
                    show: true,
                    id: item.assemble_id,
                  })}}},[_vm._v(" "+_vm._s(item.assemblies[0].assemble_city)+","+_vm._s(item.assemblies[0].assemble_state)+"-FA("+_vm._s(item.assemblies[0].sequence)+")("+_vm._s(item.assemblies[0].player_list .map(function (player) { return player.name; }) .join(","))+") ")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListAssemblyInfoModal({
                    show: true,
                    id: item.assemble_id,
                  })}}},[_vm._v(" "+_vm._s(item.assemblies[0].assemble_city)+","+_vm._s(item.assemblies[0].assemble_state)+"-FA("+_vm._s(item.assemblies[0].sequence)+")(Unassigned) ")])]),_c('br')]):_vm._e(),(item.event_type == 'game')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("Game")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"94px"}},[_vm._v(_vm._s(item.games[0].start_time_str))]),_vm._v("                "),(item.games[0].team_name == 'KING TEAM')?_c('strong',{staticStyle:{"color":"#27e600","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")]):(item.games[0].team_name == 'BWY TEAM')?_c('strong',{staticStyle:{"color":"#b96a59","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")]):(item.games[0].team_name == 'RR TEAM')?_c('strong',{staticStyle:{"color":"#ca5cdd","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")]):(item.games[0].team_name == null)?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"(Unassigned) ")]):_c('strong',{staticStyle:{"color":"#ffcccb","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListGameInfoModal({
                    show: true,
                    id: item.game_id,
                  })}}},[_vm._v(_vm._s(item.games[0].city)+","+_vm._s(item.games[0].state_name)+","+_vm._s(_vm.converTime(item.games[0].start_time))+"("+_vm._s(item.games[0].team_name)+") ")])]),_c('br')]):_vm._e(),(item.event_type == 'hotel')?_c('div',{staticClass:"text-left row-item"},[(item.hotel_detail[0].game != null)?_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("Hotel")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"80px"}}),_vm._v("                                 "),(item.hotel_detail[0].team_name != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].hotel_city)+","+_vm._s(item.hotel_detail[0].hotel_state)+"-Hotel("+_vm._s(item.hotel_detail[0].team_name)+") ")]):(item.hotel_detail[0].player_list != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].hotel_city)+","+_vm._s(item.hotel_detail[0].hotel_state)+"-Hotel("+_vm._s(item.hotel_detail[0].player_list .map(function (player) { return player.name; }) .join(","))+") ")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].hotel_city)+","+_vm._s(item.hotel_detail[0].hotel_state)+"-Hotel(Unassigned) ")])]):(item.hotel_detail[0].game == null)?_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"12px","margin-left":"-200px"}},[_vm._v("Hotel")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"80px"}}),_vm._v("                                 "),(item.hotel_detail[0].team_name != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].city)+","+_vm._s(item.hotel_detail[0].hotel_state_paid)+"-Hotel("+_vm._s(item.hotel_detail[0].team_name)+") ")]):(item.hotel_detail[0].player_list != null)?_c('strong',{staticStyle:{"color":"#e56e94","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].city)+","+_vm._s(item.hotel_detail[0].hotel_state_paid)+"-Hotel("+_vm._s(item.hotel_detail[0].player_list .map(function (player) { return player.name; }) .join(","))+") ")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListHotelInfoModal({
                    show: true,
                    id: item.hotel_id,
                  })}}},[_vm._v(" "+_vm._s(item.hotel_detail[0].city)+","+_vm._s(item.hotel_detail[0].hotel_state_paid)+"-Hotel(Unassigned) ")])]):_vm._e(),_c('br')]):_vm._e(),(item.event_type == 'kick_off_date')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("Wizfit")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"87px"}},[_vm._v(_vm._s(item.campaigns[0].kick_off_start_time_str))]),_vm._v("                "),(
                  item.school_name != null &&
                  item.campaigns[0].kick_off_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-KO("+_vm._s(item.campaigns[0].kick_off_team_name)+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].kick_off_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-KO("+_vm._s(item.campaigns[0].kick_off_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].kick_off_player_list == null &&
                  item.campaigns[0].kick_off_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-KO(Unassigned)")]):_vm._e(),(
                  item.district_name != null &&
                  item.campaigns[0].kick_off_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-KO("+_vm._s(item.campaigns[0].kick_off_team_name)+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].kick_off_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-KO("+_vm._s(item.campaigns[0].kick_off_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].kick_off_player_list == null &&
                  item.campaigns[0].kick_off_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitInfoModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-KO(Unassigned)")]):_vm._e()]),_c('br')]):_vm._e(),(item.event_type == 'gold_ball_giveaway_date')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("Wizfit")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"87px"}},[_vm._v(_vm._s(item.campaigns[0].gold_ball_start_time_str))]),_vm._v("                "),(
                  item.school_name != null &&
                  item.campaigns[0].gold_ball_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GB("+_vm._s(item.campaigns[0].gold_ball_team_name)+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].gold_ball_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GB("+_vm._s(item.campaigns[0].gold_ball_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].gold_ball_player_list == null &&
                  item.campaigns[0].gold_ball_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GB(Unassigned)")]):_vm._e(),(
                  item.district_name != null &&
                  item.campaigns[0].gold_ball_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GB("+_vm._s(item.campaigns[0].gold_ball_team_name)+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].gold_ball_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GB("+_vm._s(item.campaigns[0].gold_ball_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].gold_ball_player_list == null &&
                  item.campaigns[0].gold_ball_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGBModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GB(Unassigned)")]):_vm._e()]),_c('br')]):_vm._e(),(item.event_type == 'graduation_celebration_date')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("Wizfit")]),_vm._v(" "),_c('span',{staticStyle:{"margin-left":"87px"}},[_vm._v(_vm._s(item.campaigns[0].graduation_start_time_str))]),_vm._v("                "),(
                  item.school_name != null &&
                  item.campaigns[0].graduation_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GR("+_vm._s(item.campaigns[0].graduation_team_name)+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].graduation_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GR("+_vm._s(item.campaigns[0].graduation_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.school_name != null &&
                  item.campaigns[0].graduation_player_list == null &&
                  item.campaigns[0].graduation_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.city)+","+_vm._s(item.state)+"-GR(Unassigned)")]):_vm._e(),(
                  item.district_name != null &&
                  item.campaigns[0].graduation_team_name != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GR("+_vm._s(item.campaigns[0].graduation_team_name)+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].graduation_player_list != null
                )?_c('strong',{staticStyle:{"color":"#8c6ee39e","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GR("+_vm._s(item.campaigns[0].graduation_player_list .map(function (player) { return player.name; }) .join(","))+")")]):(
                  item.district_name != null &&
                  item.campaigns[0].graduation_player_list == null &&
                  item.campaigns[0].graduation_team_name == null
                )?_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListWizfitGRModal({
                    show: true,
                    id: item.campaign_id,
                  })}}},[_vm._v("WF-"+_vm._s(item.district_name)+"-GR(Unassigned)")]):_vm._e()]),_c('br')]):_vm._e(),(item.event_type == 'custom')?_c('div',{staticClass:"text-left row-item"},[_c('div',{staticStyle:{"text-align":"justify","font-size":"11px","margin-left":"219px"}},[_c('span',{staticStyle:{"font-size":"13px","margin-left":"-200px"}},[_vm._v("Custom Event")]),_vm._v("  "),_c('span',{staticStyle:{"margin-left":"35px"}},[_vm._v(_vm._s(item.paid_events[0].start_time_str))]),_vm._v("                "),(item.paid_events[0].team_name != null)?_c('strong',{staticStyle:{"color":"#fbc02d","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListCustomInfoModal({
                    show: true,
                    id: item.paid_event_id,
                  })}}},[_vm._v(_vm._s(item.paid_events[0].event_name)+"-"+_vm._s(item.paid_events[0].city)+","+_vm._s(item.paid_events[0].state_name)+"-("+_vm._s(item.paid_events[0].team_name)+")")]):(item.paid_events[0].player_list != null)?_c('strong',{staticStyle:{"color":"#fbc02d","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListCustomInfoModal({
                    show: true,
                    id: item.paid_event_id,
                  })}}},[_vm._v(_vm._s(item.paid_events[0].event_name)+"-"+_vm._s(item.paid_events[0].city)+","+_vm._s(item.paid_events[0].state_name)+"-("+_vm._s(item.paid_events[0].player_list .map(function (player) { return player.name; }) .join(","))+")")]):_c('strong',{staticStyle:{"color":"#a9a9a9","cursor":"pointer"},on:{"click":function($event){return _vm.toggleListCustomInfoModal({
                    show: true,
                    id: item.paid_event_id,
                  })}}},[_vm._v(_vm._s(item.paid_events[0].event_name)+"-"+_vm._s(item.paid_events[0].city)+","+_vm._s(item.paid_events[0].state_name)+"-(Unassigned)")])]),_c('br')]):_vm._e()]):_vm._e()])})],2),_c('add-filter-modal'),_c('list-game-info-modal'),_c('list-assembly-info-modal'),_c('list-hotel-info-modal'),_c('list-task-info-modal'),_c('list-pto-info-modal'),_c('list-custom-info-modal'),_c('list-wizfit-info-modal'),_c('list-wizfit-GB-modal'),_c('list-wizfit-GR-modal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }